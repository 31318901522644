import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Shoulder Press 1-1-1-1 to a 1RM`}</p>
    <p>{`Pendlay Rows 2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 rounds for max reps of:`}</p>
    <p>{`Strict HSPU’s`}</p>
    <p>{`Ring Rows (rx=chest under rings, body in planked)`}</p>
    <p>{`Rest 2:00 between sets.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      